import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './app/home/home.component';
import { IndexComponent } from './app/home/pages/index/index.component';

const routes:Routes = [
  {

    //la busqueda de la ruta se hace desde arriba hacia abajo, asi que ojo con la definición de las rutas
    path:'',
    component:HomeComponent,
    children :[
      
      {
        path:'',
        component:IndexComponent
      },
      {
        path:'flujos',
        loadChildren: () => import('../flujos/flujo.module').then(m => m.FlujoModule)
        
      },
      {
        path:'nucleo',
        loadChildren: () => import('../core/core.module').then(m => m.CoreModule)
        
      },
      {
        path:'solicitudes',
        loadChildren: () => import('../solicitudes/solicitudes.module').then(m => m.SolicitudesModule)
        
      },
      {
        path:'recursos',
        loadChildren: () => import('../recursos/recursos.module').then(m => m.RecursosModule)
        
      },
      {
        path:'vinformulario',
        loadChildren: () => import('../vinformulario/formulario-titulado.module').then(m => m.FormularioTituladoModule)
        
      },
      {
        path:'aforocovid',
        loadChildren: () => import('../aforocovid/aforocovid.module').then(m => m.AforocovidModule)
        
      },
      {
        path:'consulta_renovante',
        loadChildren: () => import('../consulta_renovante/consulta-renovante.module').then(m => m.ConsultaRenovanteModule)
      },
      {
        path:'cuestionario',
        loadChildren: () => import('../cuestionario/cuestionario.module').then(m => m.CuestionarioModule)
      },
      {
        path:'consulta_titulados',
        loadChildren: () => import('../vinculo/consulta_titulados/consulta-titulados.module').then(m => m.ConsultaTituladosModule)
      },
      {
        path:'decreto_nombramiento',
        loadChildren: () => import('../vraf/decreto-nombramiento/decreto-nombramiento.module').then(m => m.DecretoNombramientoModule)
      },
      {
        path:'bolsa_trabajo',
        loadChildren: () => import('../vinculo/bolsa-trabajo/bolsa-trabajo.module').then(m => m.BolsaTrabajoModule)
      },      
      {  
        path:'carta_cobranza',
        loadChildren: () => import('../carta_cobranza/carta-cobranza.module').then(m => m.CartaCobranzaModule)
      },
      {  
        path:'mantenedor',
        loadChildren: () => import('../mantenedor/mantenedor.module').then(m => m.MantenedorModule)
      },
      {  
        path:'crm',
        loadChildren: () => import('../crm/crm.module').then(m => m.CRMModule)
      },
      {  
        path:'convenio',
        loadChildren: () => import('../vinculo/convenio/convenio.module').then(m => m.ConvenioModule)
      },
      {
        path:'practica',
        loadChildren: () => import('../vinculo/practica/practica.module').then(m => m.PracticaModule)
      },
      {
        path:'educacion_continua',
        loadChildren: () => import('../educacion-continua/educacion-continua.module').then(m => m.EducacionContinuaModule)
      },
      {

        path:'boletas',
        loadChildren:()=>import('../boletas/boletas.module').then(m => m.BoletasModule)
      },
      {
        path:'admin_salas',
        loadChildren: () => import('../vra/admin-sala/admin-sala.module').then(m => m.AdminSalaModule)

      },
      {
        path:'formulario_ase',
        loadChildren: () => import('../formulario-ase/formulario-ase.module').then(m => m.FormularioASEModule)

      },
      {
        path:'presupuesto',
        loadChildren: () => import('../presupuesto/presupuesto.module').then(m => m.PresupuestoModule)

      },
      {
        path:'reporteria',
        loadChildren: () => import('../reporteria/reporteria.module').then(m => m.ReporteriaModule)
      },
      {
        path:'desarrollo_carreras',
        loadChildren: () => import('../desarrollo_carreras/desarrollo-carreras.module').then(m => m.DesarrolloCarrerasModule)

      },
      {
        path:'admision',
        loadChildren: () => import('../admision360/admision360.module').then(m => m.Admision360Module)

      },
      {
        path:'formulario_vinc_medio',
        loadChildren: () => import('../formulario_vinc_medio/formulario_vinc_medio.module').then(m => m.FormularioVincMedioModule)

      }

      
      
    ]
  }
]


@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports :[
    RouterModule
  ]
})
export class ConfigRoutingModule { 
  
}
